@font-face {
  font-family: 'magra';
  /* Specify the font-family name */
  src: url('/public/Magra-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'magra';
  /* Specify the font-family name */
  src: url('/public/Magra-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: 'inter';
  /* Specify the font-family name */
  src: url('/public/Inter-VariableFont_slnt,wght.ttf');
  font-weight: normal;
}

* {
  font-family: inter;
}

button {
  background-color: #00B3FF;;
  color: white;
  border: none;
  padding: 5px 20px;
  border-radius: 0.5em;
  cursor: pointer;
}

button:hover {
  background-color: #009EE2;
}

.button-text {
  font-family: inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.icon-button {
  display: inline-flex;
  align-items: center;
  border: none;
  cursor: pointer;
  background: none;
  padding: 0px;
}

.icon-button:hover {
  background: none;
  color: #beecff;
}

.text-icon-button {
  display: inline-flex;
  align-items: center;
  gap: 10px
}

.background-color-1 {
  background-color: #E5F5FC;
}

.color-1 {
  background-color: #E5F5FC;
}

.color-2 {
  color: #009EE2;
  ;
}

.content-container {
  max-width: 1180px;
  padding: 2em 3em 4em 3em;
  margin: 0 auto;
}

.clickable {
  cursor: pointer;
}

/* HEADER */

.links a.active {
  color: black;
}

.account-name-logout {
  display: inline-flex;
  gap: 10px;
  align-items: center;
}

.header-title-text {
  color: #FFF;
  text-align: center;
  font-family: Magra;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: 70px;
  text-transform: uppercase;
}

.page-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 55px;
  padding: 30px;
  background-image: url('/public/gds-header-background.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #ffffff;
  /* Text color */
  height: 380px;
}

.page-header-top-section {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.page-header-title {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 500px;
}

.logo-container {
  flex: 1;
}

.logo {
  width: 100px;
  /* Adjust size as needed */
  height: auto;
}

.links {
  display: flex;
}

.links a {
  color: #ffffff;
  /* Link color */
  text-decoration: none;
  margin-right: 20px;
}

.header-title {
  font-size: 24px;
}

/* DROPDOWN MENU */

/* Hide Dropdowns by Default */
.dropdown-menu ul {
  display: none;
  position: absolute;
  list-style: none;
  top: 60px;
  z-index: 100;
}

.dropdown-menu:hover ul {
  display: block;
}

/* Fisrt Tier Dropdown */
.dropdown-menu ul li {
  width: 190px;
  margin: 0;
  text-align: center;
  position: relative;
  background-color: #00B3FF;
}

.triangle {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

/* LOGIN PAGE */

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #E7F4FF;
  padding: 10vh;
  border-radius: 5px;
}

.login-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.login-form {
  width: 250px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Styling for the input fields */
input[type="text"],
input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: none;
  background: white;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 1em;
}

input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="password"]::placeholder {
  color: #dcdcdc;
}


/* LECTURE LIST */

.lecture-list-view {
  display: flex;
  gap: 30px;
  flex-direction: column;
}

.lecture-list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 40px;
  border: 1px solid rgba(148, 225, 255, 0.4);
  border-radius: 20px;
  padding: 30px;

}

/* LECTURE PREVIEW */

.lecture-preview {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  height: 130px;
  border-radius: 20px;
  background: linear-gradient(90deg, rgba(204, 236, 249, 0.40) 0%, rgba(229, 245, 252, 0.00) 100%);
}

.lecture-preview-data {
  display: flex;
  flex-direction: row;
  height: inherit;
  gap: 40px;
}

.lecture-preview-thumbnail {
  cursor: pointer;
  border-radius: 20px;

}

.thumbnail-image {
  border-radius: 20px;
  height: 100%;
}

.lecture-preview-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.lecture-preview-info-categories{
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.lecture-preview-button:hover {
  color: white;
}

/* UPLOAD */

.upload-container {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.upload-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

label {
  margin-bottom: 5px;
}

textarea,
input[type='file'] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* FOOTER */

.footer-container {
  background-color: #009EE2;
  color: #FFFFFF;
}

.footer-container-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.footer-logo-and-info {
  max-width: 25em;
  margin-right: 5em;
  flex-grow: 1;
}

.footer-logo-and-info img {
  margin: 1.25em 0 1em 0;
}

.footer-info p {
  margin: 5px 0;
}

.footer-social a {
  color: white;
  margin: 0 10px;
  font-size: 1.5em;
  transition: color 0.3s ease;
}

.footer-social a:hover {
  color: #ddd;
}

.footer-conference a {
  color: white;
  text-decoration: underline;
}

.footer-contacts {
  margin-right: 5em;
  padding-top: 5em;
  width: 12em;
}

.footer-pages {
  padding-top: 5em;
  width: 24em;
}


.footer-contacts h4,
.footer-pages h4 {
  margin-bottom: 10px;
}

.footer-contacts p,
.footer-pages p {
  margin: 5px 0;
}

.footer-contacts a,
.footer-pages a {
  color: white;
  text-decoration: none;
  margin: 0 5px;
}

.footer-contacts a:hover,
.footer-pages a:hover {
  text-decoration: underline;
}

.footer-bottom {
  background-color: #ffffff;
  color: black;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-logos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3rem;
}

.footer-logos span {
  flex-grow: 1;
  text-align: right;
  justify-content: right;
  display: inline-flex;
  align-items: center;
}

.footer-logos img {
  object-fit: cover;
  max-width: 100%;
  height: 3rem;
}

.footer-bottom p {
  margin-top: 10px;
}

/* Category filter */

.category-filter-container {
  width: 100%;
  height: 2.625rem;
  background-color: #E5F5FC;
  border-radius: 0.5rem;
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 0;
  margin-bottom: 2rem;
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.625rem;
  text-transform: capitalize;
  justify-content: space-evenly;
}

.category-filter-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.category-filter-button.underlined {
  text-decoration: underline;
}

.category-filter-button.disabled {
  opacity: 0.3;
  text-decoration: none;
}

.category-filter-button:hover {
  text-decoration: underline;
}

.category-filter-icon {
  width: 1.625rem;
  height: 1.625rem;
  display: inline-block;
  border-radius: 2rem;
}

.category-filter-icon-small {
  width: 1.2rem;
  height: 1.2rem;
  display: inline-block;
  border-radius: 2rem;
}

.art-audio {
  background-color: #C0E24E;
}

.business {
  background-color: #FC8498;
  ;
}

.design {
  background-color: #E7AD2F;
}

.technical {
  background-color: #009EE2;
}

.industry-support {
  background-color: #C03232;
}

.other {
  background-color: #ACACAC;
}

/* LECTURE PAGE */

.lecture-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.breadcrumb {
  font-size: 14px;
  color: #777;
  margin-bottom: 20px;
}

.breadcrumb a {
  color: #777;
  text-decoration: none;
}

.lecture-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.lecture-summary {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}

.lecture-details {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}

.lecture-about,
.lecture-speaker {
  width: 48%;
}

.lecture-about h2,
.lecture-speaker h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.lecture-about p,
.lecture-speaker p {
  font-size: 14px;
  color: #555;
}

.button-group {
  margin-top: 20px;
}

